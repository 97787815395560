.App__muted-btn:hover {
  background-color: #c7c7c7;
}

.App__muted-btn {
  color: #4e4e4e;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  height: 42px;
  width: 100%;
  border-radius: 5px;
  background-color: #e9e9e9;
}

.App__secondary-btn {
  color: var(--blue1);
  border: 2px solid var(--blue1);
  outline: none;
  text-align: center;
  cursor: pointer;
  height: 42px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--white);
}

.App__primary-btn {
  color: var(--white);
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;
  height: 42px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--blue1);
}

.App-btn-group {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  column-gap: 10px;
  padding: 16px 0;
}
.App__secondary-btn:hover {
  background-color: var(--blue1);
  color: var(--white);
}

.App__primary-btn:hover {
  background-color: var(--dark-blue);
}
