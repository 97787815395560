.MetLife-Loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
}

.MetLife-Loader .loader-wrapper {
  background-color: var(--white);
  padding: 2em 2.5em;
  border-radius: 8px;
}

/**
  * ==============================================
  * Dot Typing
  * ==============================================
  */
.dot-typing {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;

  box-shadow: 9984px 0 0 0 #2b99e3, 9999px 0 0 0 #2b99e3, 10014px 0 0 0 #2b99e3;
  animation: dot-typing 1.5s infinite linear;
}

@keyframes dot-typing {
  0% {
    box-shadow: 9984px 0 0 0 #2b99e3, 9999px 0 0 0 var(--dark-blue),
      10014px 0 0 0 var(--green);
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #2b99e3, 9999px 0 0 0 var(--dark-blue),
      10014px 0 0 0 var(--green);
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #2b99e3, 9999px 0 0 0 var(--dark-blue),
      10014px 0 0 0 var(--green);
  }
  50% {
    box-shadow: 9984px 0 0 0 #2b99e3, 9999px -10px 0 0 var(--dark-blue),
      10014px 0 0 0 var(--green);
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #2b99e3, 9999px 0 0 0 var(--dark-blue),
      10014px 0 0 0 var(--green);
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #2b99e3, 9999px 0 0 0 var(--dark-blue),
      10014px -10px 0 0 var(--green);
  }
  100% {
    box-shadow: 9984px 0 0 0 #2b99e3, 9999px 0 0 0 var(--dark-blue),
      10014px 0 0 0 var(--green);
  }
}
