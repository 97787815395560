.container {
  background-color: var(--white);
  display: flex;
  justify-content: center;
}
.copyrightStatment {
  width: 100%;
}
.copyright-info-mobile_width {
  text-align: center;
  justify-content: center;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 20px;
  border-top: var(--light-gray) solid 2px;
}
.privacy-statement1 {
  color: var(--blue2);
}
.mobileContainer {
  background-color: var(--white);
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
.horizontalDivider {
  background-color: var(--light-gray);
  height: 60px;
  width: 2px;
}
.mobileInnerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0;
  height: 100%;
  width: 100%;
  margin: 0 0;
}
.mobileCountryTab {
  border: var(--light-gray) 2px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 99.5%;
}
.dropdown {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
.worldwide {
  justify-content: center;
  display: flex;
  width: 100%;
}
.mobileLogos {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  justify-content: space-between;
  padding-bottom: 1.5em;
  padding-top: 0.25em;
}
.links ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 25px;
}
.call {
  color: #6e6e6e;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mobileInnerContainer a {
  font-size: 14px;
  text-decoration: none;
  color: var(--blue2);
}
.innerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 100%;
  padding: 20px 0;
  margin: 0 auto;
}

.footerInfoLinks {
  display: none;
}

.innerContainer a:hover {
  text-decoration: underline;
}

.innerContainer a {
  font-size: 14px;
  text-decoration: none;
  color: var(--blue2);
}

.footerInfoLinks ul li {
  list-style: none;
  margin-bottom: 0.3em;
}

.footerInfoLinks ul {
  padding: 0;
  margin: 0;
}

.footerInfoLink h3 {
  font-weight: 600;
  font-size: 18px;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.footerWrapperMid {
  display: none;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 4em;
}

.sectionDivider {
  display: none;
  width: 100%;
  background-color: #e6e6e6;
  height: 2px;
  margin: 1.5em 0;
}

.countrySelectContainer {
  display: flex;
  justify-content: space-between;
}

.countrySelectWrap {
  display: none;
  align-items: center;
  margin-right: 16px;
}
.countrySelectWrap span {
  font-size: 16px;
  font-weight: 500;
}

.innerMidColumn1 {
  width: auto;
}

.innerMidColumn2 {
  display: none;
  align-items: center;
}

.countryHeading,
.followUsHeading {
  display: none;
  font-size: 15px;
  margin-right: 8px;
}

.fbIcon {
  margin-right: -4px;
}

.socialMediaLinks {
  display: flex;
  gap: 10px;
  align-items: center;
}

.brandIcon:hover svg {
  fill: var(--blue2) !important;
}

.brandIcon svg {
  height: 24px;
  width: 24px;
  fill: var(--dark-blue) !important;
}

.brandIcon {
  color: var(--dark-blue);
  fill: var(--dark-blue);
}

.footerWrapperBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.footerMelifeLogo svg {
  height: 22px;
  width: 100%;
}

.footerMelifeLogo {
  display: none;
}

.bottomLinks {
  width: 100%;
  margin-top: 10px;
}

.bottomLinks ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  gap: 25px;
}

.callNumber {
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}

.privacy-statement,
.terms-conditions {
  list-style-type: none;
}

.copyright-info-mobile {
  text-align: justify;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 12px;
  line-height: 20px;
  border-top: var(--light-gray) solid 2px;
}

.copyright-info {
  display: none;
  font-size: 14px;
  line-height: 22px;
}

@media screen and (min-width: 576px) {
  /* .copyright-info-mobile {
    border-top: none;
    text-align: center;
  } */
  .footerMelifeLogo {
    display: initial;
  }
  .sectionDivider {
    display: block;
  }

  .countrySelectWrap,
  .footerWrapperMid,
  .innerMidColumn2 {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .countryHeading,
  .followUsHeading {
    display: inline;
  }

  .footerInfoLinks {
    row-gap: 0;
    column-gap: 5.5vw;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 4vw;
  }
}

@media screen and (min-width: 768px) {
  .footerWrapperBottom {
    flex-direction: row;
    justify-content: flex-start;
    padding: 25px 0;
  }
  .privacy-statement,
  .copyright-info {
    list-style: disc;
    display: list-item;
  }

  .copyright-info-mobile {
    display: none;
  }

  .bottomLinks {
    width: auto;
    margin-top: 0;
    margin-left: 5em;
  }
}
@media screen and (min-width: 922px) {
  .innerContainer {
    width: 90%;
  }
}

@media screen and (min-width: 1200px) {
  .innerContainer {
    max-width: 90%;
  }
  .footerInfoLinks {
    column-gap: 6vw;
  }
}

@media screen and (min-width: 1500px) {
  .footerInfoLinks {
    column-gap: 10vw;
  }
  /* .innerContainer {
    max-width: 70%;
  } */
}
@media screen and (max-width: 480px) {
  .copyright-info-mobile_width {
    justify-content: center;
    font-size: 9px;
    padding: 10px 16px;
  }
  .mobileInnerContainer a {
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    color: var(--blue2);
  }
  .call {
    color: #6e6e6e;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
