.card {
  background: var(--white);
  border-radius: 15px;
  padding: 24px 32px;
  width: auto;
  height: auto;
}
/* Write Responsive CSS, breakpoints min-width = 576px, 768px, 922px, 1080px, 1200px */

/* 576px */
@media screen and (min-width: 576px) {
  .card {
    padding: 24px 32px;
    width: auto;
    height: auto;
    min-width: 200px;
    min-height: 120px;
  }
}

/* 768px */

@media screen and (min-width: 768px) {
  .card {
    padding: 24px 32px;
    width: auto;
    height: auto;
    min-width: 200px;
    min-height: 120px;
  }
}

/* 922px */

@media screen and (min-width: 922px) {
  .card {
    padding: 24px 32px;
    width: auto;
    height: auto;
    min-width: 200px;
    min-height: 120px;
  }
}

/* 1080px */

@media screen and (min-width: 1080px) {
  .card {
    padding: 24px 32px;
    width: auto;
    height: auto;
    min-width: 200px;
    min-height: 120px;
  }
}

/* 1200px */

@media screen and (min-width: 1200px) {
  .card {
    padding: 24px 32px;
    width: auto;
    height: auto;
    min-width: 400px;
    min-height: 120px;
  }
}
@media screen and (max-width: 480px) {
  .card {
    padding: 9px 0px;
    width: auto;
    height: auto;
    min-width: 200px;
    min-height: 120px;
  }
}
