@font-face {
  font-family: "Noto Sans", sans-serif;
  src: url("./fonts/NotoSans/NotoSans-Black.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-BlackItalic.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-Bold.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-BoldItalic.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-ExtraBold.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-ExtraBoldItalic.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-ExtraLight.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-ExtraLightItalic.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-Italic.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-Light.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-LightItalic.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-Medium.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-MediumItalic.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-Regular.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-SemiBold.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-SemiBoldItalic.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-Thin.ttf") format("truetype"),
    url("./fonts/NotoSans/NotoSans-ThinItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MetLife Circular TT";
  /* MetLife Circular */
  /* Noto Sans */
  src: url("./fonts/MetLifeCircular/MetLifeCircularTT-Bold.ttf") format("truetype"),
    url("./fonts/MetLifeCircular/MetLifeCircularTT-BoldItalic.ttf")
      format("truetype"),
    url("./fonts/MetLifeCircular/MetLifeCircularTT-Light.ttf") format("truetype"),
    url("./fonts/MetLifeCircular/MetLifeCircularTT-LightItalic.ttf")
      format("truetype"),
    url("./fonts/MetLifeCircular/MetLifeCircularTT-Medium.ttf") format("truetype"),
    url("./fonts/MetLifeCircular/MetLifeCircularTT-MediumItalic.ttf")
      format("truetype"),
    url("./fonts/MetLifeCircular/MetLifeCircularTT-Normal.ttf") format("truetype"),
    url("./fonts/MetLifeCircular/MetLifeCircularTT-NormalItalic.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}
