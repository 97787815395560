.PaymentDone__heading h2 {
  font-family: "MetLife Circular", sans-serif;
  color: var(--darkest-gray);
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  padding: 0 16px;
  margin: 0px;
  margin-top: 20px;
}
.PaymentDone__container {
  padding: 0 21px;
}
.PaymentDone__container .toast-message .toast-content .description .a {
  text-decoration: none;
  margin-left: 10px;
}
.empty_spacing {
  height: 8px;
}

@media screen and (min-width: 480px) {
  .PaymentDone__container {
    margin: auto;
    padding: 0 16px;
    width: 81%;
  }
  .empty_spacing {
    height: 18px;
  }
}

@media screen and (min-width: 576px) {
  .PaymentDone__container {
    margin: auto;
    padding: 0 16px;
    width: 73%;
  }
}

@media screen and (min-width: 768px) {
  .PaymentDone__container {
    margin: auto;
    padding: 0 16px;
    width: 75%;
  }
}

@media screen and (min-width: 1024px) {
  .PaymentDone__container {
    /* margin: auto; */
    padding: 0 16px;
  }
}

.PaymentDone__card-contents .card-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.PaymentDone__card-contents .card-content {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.PaymentDone__card-contents .card-content .card-footer .content-row,
.PaymentDone__card-contents .card-content .content-row {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  row-gap: 5px;
}

.PaymentDone__card-contents .card-content .content-row .col-1,
.PaymentDone__card-contents .card-content .content-row .col-2 {
  width: 100%;
  margin-top: 10px;
}

.PaymentDone__card-contents .card-content .content-row .label {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  color: var(--dark-gray);
}
.PaymentDone__card-contents .card-content .content-row .value {
  font-size: 18px;
  font-weight: 600;
  line-height: 24.52px;
  text-align: left;
  color: var(--darkest-gray);
}

.PaymentDone__agreement-container {
  padding: 16px;
}
.PaymentDone__agreement-container label {
  display: flex;
  align-items: center;
}

.PaymentDone__agreement-container label .PaymentDone__agreement-text {
  /* font-family: Noto Sans; */
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-left: 8px;
}

.PaymentDone__agreement-container label .PaymentDone__agreement-text button:hover {
  color: #006ca6;
}

.PaymentDone__agreement-container label .PaymentDone__agreement-text button {
  border: none;
  padding: 0;
  margin: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  color: var(--blue2);
  text-decoration: none;
  font-weight: 600;
}

.PaymentDone__btn-group {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 0;
  justify-content: space-between;
}

.PaymentDone__container .toast-message {
  min-width: 285px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 5px 0px var(--black-1a);
  column-gap: 1em;
  padding: 16px 25px;
  margin: 16px 0;
  margin-top: 3em;
  border-radius: 8px;
}
.PaymentDone__container .toast-message .toast-content .description {
  position: relative;
  top: -1px;
}

.PaymentDone__container .toast-message .toast-content {
  display: flex;
  width: 100%;
  column-gap: 10px;
  align-items: center;
}

.PaymentDone__container .toast-message.success {
  background: #e5f7f5;
}

.PaymentDone__container .toast-message .description {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
  max-width: 80%;
}

.PaymentDone__container .PaymentDone__agency-details {
  padding: 20px 0;
}

.PaymentDone__container .PaymentDone__agency-details .agency-code {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  line-height: 22px;
  color: var(--darkest-gray);
  margin-bottom: 14px;
}

.PaymentDone__container .PaymentDone__agency-details .agency-disclaimer {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--darkest-gray);
  max-width: 970px;
}
.PaymentDone__btn-group button.print-btn {
  display: none;
}
@media screen and (min-width: 768px) {
  .PaymentDone__container .toast-message .toast-content .description {
    column-gap: 5px;
    width: 100%;
    margin-left: 5px;
  }
  .PaymentDone__container .toast-message {
    margin: 0;
    margin-top: 3em;
    margin-bottom: 1.5em;
  }

  .PaymentDone__agreement-container {
    padding: 16px 0;
  }

  .PaymentDone__btn-group {
    flex-direction: row;
    row-gap: 0;
    padding: 0;
    column-gap: 20px;
    justify-content: center;
  }

  .PaymentDone__btn-group button.print-btn {
    display: initial;
  }

  .PaymentDone__btn-group button {
    max-width: 145px;
    height: 40px;
  }
}

@media screen and (min-width: 768px) {
  .PaymentDone__card-contents .card-content .content-row .col-1,
  .PaymentDone__card-contents .card-content .content-row .col-2 {
    width: 100%;
    margin-top: 0;
  }

  .PaymentDone__card-contents .card-content .card-footer .content-row,
  .PaymentDone__card-contents .card-content .content-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-top: 16px;
  }
}
.PaymentDone__heading h2 {
  padding: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
}
@media screen and (min-width: 480px) {
  .PaymentDone__heading h2 {
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
  }
}

@media screen and (min-width: 768px) {
  .PaymentDone__heading h2 {
    padding: 0;
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
  }
  .PaymentDone__btn-group {
    justify-content: flex-start;
  }
  .PaymentDone__container {
    padding-bottom: 2em;
  }
}

@media screen and (min-width: 1024px) {
  .PaymentDone__container .toast-message .toast-content .description {
    display: flex;
    column-gap: 5px;
    width: 100%;
    margin-left: 5px;
  }
  .PaymentDone__heading h2 {
    padding: 0;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    text-align: left;
  }
}

.payment-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 16px;
}

.payment-status-container .payment-status-message {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: var(--dark-blue);
}
@media screen and (max-width: 768px) {
  .PaymentDone__container .toast-message {
    min-width: 285px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 5px 0px var(--black-1a);
    -moz-column-gap: 1em;
    column-gap: 1em;
    padding: 16px 25px;
    margin: 16px 0px;
    /* margin-top: 3em; */
    border-radius: 8px;
  }
  .PaymentDone__card-contents .card-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  .PaymentDone__card-contents .card-content .content-row .label {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    color: var(--dark-gray);
  }
  .PaymentDone__card-contents .card-content .content-row .value div {
    text-align: left;
    min-width: 40vw;
  }
  .PaymentDone__card-contents .card-content .content-row .value {
    font-size: 16px;
    font-weight: 500;
    line-height: 24.52px;
    text-align: left;
    color: var(--darkest-gray);
  }
  .PaymentDone__card-contents .card-content .content-row .col-1,
  .PaymentDone__card-contents .card-content .content-row .col-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
@media screen and (max-width: 667px) {
  .PaymentDone__card-contents .card-content .content-row .value div {
    text-align: left;
    min-width: 40vw;
    margin-left: 8px;
  }
}
@media screen and (max-width: 576px) {
  .PaymentDone__card-contents .card-content .content-row .value div {
    text-align: left;
    min-width: 44vw;
    margin-left: 8px;
  }
}
@media screen and (max-width: 480px) {
  .PaymentDone__card-contents .card-content .content-row .value div {
    text-align: left;
    min-width: 50vw;
  }
}
@media screen and (max-width: 453px) {
  .PaymentDone__card-contents .card-content .content-row .value div {
    text-align: left;
    min-width: 51vw;
    margin-left: 8px;
  }
}
@media screen and (max-width: 382px) {
  .PaymentDone__card-contents .card-content .content-row .value div {
    text-align: left;
    min-width: 56vw;
  }
}

@media screen and (min-width: 480px) {
  .PaymentDone__btn-group button {
    max-width: 180px;
  }
}
