.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
  width: 100%;
  border-bottom: 2px solid var(--blue2);
  overflow: hidden;
}
.mainContainer {
  position: relative;
  margin-bottom: 20px;
}
.inputDatePickerContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
  font-size: 16px;
  border-bottom: 2px solid var(--blue2);
  overflow: hidden;
}

div.is-error {
  font-size: 14px;
  padding: 6px 0;
  color: var(--red);
}

.inputContainer label {
  position: absolute;
  margin-bottom: 5px;
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  top: 20px;
  left: 10px;
  color: var(--medium-gray);
  transition: all 0.2s ease-in-out;
  z-index: 1;
}

.inputContainer input {
  position: relative;
  height: 38px;
  width: 100%;
  border: none;
  outline: none;
  padding-top: 12px;
  z-index: 10;
  text-indent: 10px;
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
}

.inputContainer textarea {
  min-height: 80px;
  resize: none;
  position: relative;
  height: 38px;
  width: 100%;
  border: none;
  outline: none;
  padding: 0 8px;
  padding-top: 25px;
  z-index: 10;
  /* text-indent: 10px; */
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
}

.inputContainer select {
  resize: none;
  padding-top: 10px;
  position: relative;
  height: 50px;
  width: 100%;
  border: none;
  outline: none;
  z-index: 10;
  text-indent: 10px;
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  background: transparent;
}

.inputContainer.inputHasValue label,
.inputContainer input:focus ~ label {
  font-size: 12px !important;
  top: 0 !important;
}

.inputContainer.inputHasValue textarea ~ label,
.inputContainer textarea:focus ~ label {
  font-size: 12px !important;
  top: 5px !important;
}

.inputContainer.inputHasValue textarea,
.inputContainer textarea:focus {
  margin-top: 25px !important;
  padding-top: 0 !important;
}

.inputContainer select.inputHasValue label,
.inputContainer select:focus ~ label {
  /* font-size: 12px !important; */
  background-color: transparent;
  padding: 0;
  top: 5px !important;
  z-index: 5;
}

.inputContainer select ~ label {
  background-color: white;
  padding: 3px 0;
  z-index: 20;
}

.inputContainer.is-invalid {
  border-color: var(--red) !important;
}

.selectWrapper {
  height: 100%;
  width: 100%;
  min-height: 45px;
}

.selected .value {
  position: relative;
  font-size: 15px;
}
.selected .arrow {
  fill: var(--light-gray);
  transition: all 0.2s ease-in-out;
  position: relative;
}
.selected {
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  height: inherit;
  z-index: 10;
  padding: 0 8px;
}
.selected:focus ~ .optionList {
  z-index: 20;
  top: 60px;
  opacity: 1;
}

.selected:focus .arrow {
  margin-top: -6px;
  transform: rotate(-180deg);
}

.optionList {
  transition: all 0.2s ease-in-out;
  opacity: 0;
  position: absolute;
  height: fit-content;
  width: 100%;
  top: 50px;
  background: var(--white);
  z-index: -1;
  border-radius: 20px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.102);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.optionList .option.active div {
  color: var(--blue2);
  font-size: 16px;
  font-weight: 600;
  line-height: 21.79px;
  text-align: left;
}

.optionList .option:hover {
  background-color: var(--light-gray);
}
.optionList .option {
  padding: 0 16px;
  margin: 0;
}

.optionList .option div {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  border-bottom: 0.5px solid var(--gray);
}

.optionList .option:last-child div {
  border-bottom: none;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.checkbox {
  width: 8px;
  height: 8px;
  padding: 3px;
  border: 2px solid var(--black);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.checkbox.is-error {
  border: 2px solid var(--red);
  width: 8px;
  height: 8px;
  padding: 3px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.checkmark {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.label {
  margin-left: 4px;
  font-size: 14px;
  color: var(--darkest-grey);
}
.input-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pre-populate-item {
  opacity: 0;
  position: absolute;
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.79px;
  text-align: left;
  vertical-align: middle;
  top: 21px;
  left: 20px;
  z-index: -1;
  transition: all 0.2s ease-in-out;
}

.inputContainer.hasPrepopulateItem input.has-value + .pre-populate-item,
.inputContainer.hasPrepopulateItem input:focus + .pre-populate-item {
  opacity: 1;
  z-index: initial;
  left: 10px;
}

.inputContainer.hasPrepopulateItem input {
  text-indent: 3em;
}

.inputDropDownContainer {
  overflow: auto;
  position: initial;
}

.radio input {
  display: none;
}
.radio input + span {
  text-transform: capitalize;
  /* display: inline-block; */
  position: relative;
  padding-left: 32px;
  /* top: 6px; */
  align-self: center;
  line-height: 30px;
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
.radio input + span:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  margin-right: 5px;
  width: 20px;
  height: 20px;
  border: 2px solid var(--phillipine-gray);
  background: var(--white);
}
.radio input + span:after {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  background: var(--raisin-black);
  position: absolute;
  border-radius: 50%;
  top: 6px;
  left: 6px;
  opacity: 0;
  transform: scale(0, 0);
  transition: all 0.2s cubic-bezier(0.64, 0.57, 0.67, 1.53);
}
.radio input:checked + span:after {
  opacity: 1;
  transform: scale(1, 1);
}
.customInputComponent {
  overflow: hidden !important;
  overflow-y: hidden !important;
}
.customInputComponent input {
  margin-bottom: -4px;
}
