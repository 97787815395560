.PaymentOverview__heading h2 {
  font-family: "MetLife Circular", sans-serif;
  color: var(--darkest-gray);
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  padding: 0;
  margin: 0;
  margin-top: 14px;
  margin-bottom: 8px;
}
@media screen and (min-width: 480px) {
  .PaymentOverview__heading h2 {
    font-family: "MetLife Circular", sans-serif;
    color: var(--darkest-gray);
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-top: 18px;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 768px) {
  .PaymentOverview__heading h2 {
    font-family: "MetLife Circular", sans-serif;
    color: var(--darkest-gray);
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 1024px) {
  .PaymentOverview__heading h2 {
    font-family: "MetLife Circular", sans-serif;
    color: var(--darkest-gray);
    font-size: 32px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-top: 14px;
    margin-bottom: 20px;
  }
}
.PaymentOverview__container {
  max-width: 564px;
  padding: 0 20px;
  margin: auto;
}
@media screen and (min-width: 480px) {
  .PaymentOverview__container {
    max-width: 564px;
    width: 80%;
    padding: 0 20px;
    margin: auto;
  }
}
.PaymentOverview__card-contents .card-heading {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.PaymentOverview__card-contents .card-content {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
}

.PaymentOverview__card-contents .card-footer .PaymentOverview__content-row,
.PaymentOverview__card-contents .card-content .PaymentOverview__content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PaymentOverview__card-contents .card-content .PaymentOverview__content-row .label {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.PaymentOverview__card-contents .card-content .PaymentOverview__content-row .value {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.PaymentOverview__agreement-container {
  padding: 16px;
  display: flex;
  align-items: center;
}
.PaymentOverview__agreement-container label {
  display: flex;
  align-items: center;
}

.PaymentOverview__agreement-container .PaymentOverview__agreement-text {
  /* font-family: Noto Sans; */
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-left: 8px;
}

.PaymentOverview__agreement-container .PaymentOverview__agreement-text a:hover {
  color: #006ca6;
}

.PaymentOverview__agreement-container .PaymentOverview__agreement-text a {
  border: none;
  padding: 0;
  margin: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  color: var(--blue2);
  text-decoration: none;
  font-weight: 600;
}

.PaymentOverview__btn-group {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  gap: 25px;
  padding: 0 16px;
  justify-content: space-between;
}

.PaymentOverview__container .toast-message {
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 0px 5px 0px var(--black-1a);
  column-gap: 1em;
  padding: 16px 25px;
  margin: 16px;
  max-width: 492px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  width: 75%;
  opacity: 0;
  transition: all ease-in-out 0.3s;
}

.toast-message.error.show {
  top: 76px;
  opacity: 1;
}

.PaymentOverview__container .toast-message.error {
  background: #fbe9e9;
}

.PaymentOverview__container .toast-message .description {
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.07px;
  text-align: left;
}

.PaymentOverview__container .toast-message .icon-description {
  display: flex;
  column-gap: 16px;
}

.PaymentOverview__agreement-checkbox.is-error {
  outline-color: var(--red);
}

@media screen and (min-width: 576px) {
  .PaymentOverview__container .toast-message {
    margin: 16px 0;
  }
  .PaymentOverview__heading h2 {
    padding-top: 6px;
  }
  .PaymentOverview__agreement-container {
    padding: 16px 0;
  }
  .PaymentOverview__btn-group {
    padding: 0;
  }
  .PaymentOverview__container {
    max-width: 564px;
    width: 72%;
    padding: 0 20px;
    margin: auto;
  }
}
@media screen and (min-width: 1024px) {
  .PaymentOverview__heading h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
@media screen and (min-width: 768px) {
  .PaymentOverview__container .toast-message {
    width: 100% !important;
  }
  .toast-message.error.show {
    position: relative;
    top: 0;
    width: 100%;
    opacity: 1;
  }
  .PaymentOverview__btn-group {
    flex-direction: row;
    row-gap: 0;
    padding: 0;
    justify-content: space-between;
  }
  .PaymentOverview__btn-group button {
    max-width: 145px;
    height: 40px;
  }
}

@media screen and (max-width: 576px) {
  .PaymentOverview__custom-modal-wrapper {
    margin: 0;
    padding: 20px 0;
    width: 100%;
    min-height: 50%;
    border-radius: 0;
    transform: translateY(100px);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .PaymentOverview__custom-modal-wrapper.animation__slide_fade_in {
    opacity: 1;
    transform: translateY(30px);
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .PaymentOverview__custom-modal-wrapper {
    margin: 0;
    padding: 20px 0;
    width: 100%;
    min-height: 50%;
    border-radius: 0;
    transform: translateY(200px);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .PaymentOverview__custom-modal-wrapper.animation__slide_fade_in {
    opacity: 1;
    transform: translateY(172px);
  }
}
@media screen and (max-width: 768px) and (min-width: 481px) {
  .PaymentOverview__card-contents
    .card-content
    .PaymentOverview__content-row
    .label {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #6e6e6e;
  }
  .PaymentOverview__card-contents .card-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
}
@media screen and (max-width: 768px) {
  .PaymentOverview__agreement-container {
    padding: 24px 0px;
    display: flex;
    align-items: flex-start;
  }
  .PaymentOverview__card-contents .card-content {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 480px) {
  .PaymentOverview__btn-group {
    margin-top: 18px;
    display: flex;
    gap: 10px;
    padding: 0px;
    justify-content: space-between;
  }
  .PaymentOverview__heading h2 {
    color: var(--darkest-gray);
    font-size: 20px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    margin-top: 14px;
    margin-bottom: 8px;
  }
  .PaymentOverview__card-contents .card-heading {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 12px;
  }
  .PaymentOverview__card-contents
    .card-content
    .PaymentOverview__content-row
    .label {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #6e6e6e;
  }
  .PaymentOverview__card-contents
    .card-content
    .PaymentOverview__content-row
    .value {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #333;
  }
  .PaymentOverview__content-row .label strong,
  .PaymentOverview__content-row .value strong {
    color: #333;
    font-weight: 400;
    font-size: 16px;
  }

  .PaymentOverview__card-contents .card-footer .PaymentOverview__content-row,
  .PaymentOverview__card-contents .card-content .PaymentOverview__content-row {
    margin-bottom: 10px;
  }
  .PaymentOverview__agreement-container {
    padding: 24px 0px;
    display: flex;
    align-items: flex-start;
  }
}
