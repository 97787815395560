.react-datepicker {
  box-shadow: 0px 0px 8px 0px #0000001a;
  background: var(--white);
  border-radius: 20px;
  border: none !important;
  padding: 10px 0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  border: none;
  background: var(--white);
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: var(--blue2) !important;
  border-width: 2px 2px 0 0;
}

.react-datepicker__header.react-datepicker-year-header {
  color: var(--blue2);
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
}

.react-datepicker__day:not(.react-datepicker__day--disabled):hover,
.react-datepicker__month-text:not(.react-datepicker__month-text--disabled):hover,
.react-datepicker__quarter-text:not(.react-datepicker__quarter-text--disabled):hover,
.react-datepicker__year-text:not(.react-datepicker__year-text--disabled):hover {
  background-color: transparent;
  border-color: var(--blue2) !important;
}

.react-datepicker__day-text--selected,
.react-datepicker__month-text--selected,
.react-datepicker__quarter-text--selected,
.react-datepicker__year-text--selected {
  background: var(--blue2) !important;
}

.react-datepicker__day-text--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  background-color: transparent !important;
  border-color: transparent !important;
  cursor: not-allowed;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  width: 2rem !important;
  align-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  margin: 8px !important;
  padding: 3px 10px;
  border-radius: 25px !important;
  border: transparent solid 2px;
}

.react-datepicker__year-wrapper {
  max-width: 350px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-datepicker__navigation--previous {
  left: 40px !important;
  top: 12px !important;
}

.react-datepicker__navigation--next {
  right: 40px !important;
  top: 12px !important;
}

.react-datepicker__year-text.react-datepicker__year-text--keyboard-selected {
  background-color: transparent !important;
}
.react-datepicker-popper {
  z-index: 11;
  line-height: 0;
}

.react-datepicker__input-container svg {
  position: absolute;
  top: 40%;
  right: 0;
}

@media screen and (min-width: 414px) {
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    width: 3rem !important;
  }
}

@media screen and (min-width: 576px) {
  .react-datepicker__navigation--previous {
    left: 82px !important;
    top: 12px !important;
  }
  .react-datepicker__day,
  .react-datepicker__month-text,
  .react-datepicker__quarter-text,
  .react-datepicker__year-text {
    width: 4rem !important;
  }

  .react-datepicker__navigation--next {
    right: 82px !important;
    top: 12px !important;
  }
}
