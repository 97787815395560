/* Color Palette Variables */
:root {
    --white: #ffffff;
    --blue1: #0090da;
    --blue2: #007abc;
    --blue3: #2b99e3;
    --dark-blue: #0061a0;
    --green: #a4ce4e;
    --light-grey: #f2f2f2;
    --light-gray: #f2f2f2;
    --grey: #d9d9d6;
    --gray: #d9d9d6;
    --medium-grey: #a7a8aa;
    --medium-gray: #a7a8aa;
    --dark-grey: #6e6e6e;
    --dark-gray: #6e6e6e;
    --darkest-grey: #333333;
    --darkest-gray: #333333;
    --black: #000000;
    --black-1a: #0000001a;
    --berry: #db0a5b;
    --purple: #5f259f;
    --yellow: #ffc600;
    --teal: #00aca0;
    --red: #d42123;
    --light-silver: #d9d9d6;
    --raisin-black: #222222;
    --phillipine-gray:#8e8f91;
  }
  