.PaymentType__mainContainer {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
}
@media screen and (min-width: 480px) {
  .PaymentType__mainContainer {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    /* padding: 0 20px; */
    width: 80%;
  }
}
@media screen and (min-width: 576px) {
  .PaymentType__mainContainer {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    /* padding: 0 20px; */
    width: 72%;
  }
}

.PaymentType_cardDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.PaymentType_cardRadioButtons {
  display: flex;
  flex-direction: row;
}
.PaymentType_cardRadioButtons input[type="radio"] {
  transform: scale(1.2); /* Increase the size as desired */
}
.PaymentType_cardRadioButtons div {
  margin-right: 40px;
  display: flex;
}
.PaymentType_cardRadioButtons label {
  margin-top: 2.7px;
  margin-left: 10px;
  font-size: 16px;
}
.Card_disclaimer {
  margin: 10px 0px;
}
.Card_disclaimer small {
  font-size: 12px;
}
.PaymentType_button {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.PaymentType__heading {
  margin-bottom: 16px;
}

.PaymentType_cardDetailsRow strong {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.PaymentType__heading h1 {
  font-family: "MetLife Circular", sans-serif;
  color: var(--darkest-gray);
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  padding: 0;
  margin: 0;
  margin-top: 14px;
  margin-bottom: 8px;
}
.PaymentType_button.sm button {
  max-width: 100%;
}

@media screen and (min-width: 480px) {
  .PaymentType_button.sm button {
    max-width: 180px;
  }
}
@media screen and (min-width: 768px) {
  .PaymentType_button.sm button {
    max-width: 150px;
  }
}

.PaymentType_button.md button {
  max-width: 240px;
}

.cardInput {
  margin-top: 16px;
  margin-bottom: 32px;
  align-items: center;
  justify-content: center;
}

.PaymentType_cardHeading h3 {
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-bottom: 0.5em;
}

.PaymentType__tabs-container {
  position: relative;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--light-silver);
}

.PaymentType__tabs-container .slider {
  position: absolute;
  bottom: 0;
  height: 3px;
  width: 130px;
  background-color: var(--blue2);
  transition: all 0.3s ease-in-out;
}

.PaymentType__tabs-container .slider.opt-2 {
  width: 179px;
  left: 140px;
}

.PaymentType__tab {
  padding: 12px;
  padding-top: 0;
  gap: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 400;
  color: var(--darkest-gray);
  font-size: 16px;
}
.PaymentType__tab.selected {
  font-weight: 700;
}
.PaymentType__tab.disabled {
  cursor: not-allowed;
  color: var(--medium-gray);
}
.PaymentType__tab.active {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  border-bottom: 3px solid var(--blue2);
  color: var(--darkest-gray);
}

.tab-content.active {
  animation: fadeSlideIn 0.2s ease-in-out 0s 1;
}

.PaymentType__card-2 {
  margin-top: 16px;
}

.PaymentType__kycLink {
  width: fit-content;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: justify;
  text-decoration: none;
  color: var(--blue2);
  margin-top: 16px;
}

.payment-type-radios {
  margin-top: 20px;
}

.tab-content-heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.79px;
  text-align: left;
  color: var(--darkest-gray);
  margin-top: 20px;
}

.radio-group-payment-types {
  display: flex;
  gap: 20px;
  margin-top: 16px;
}

@keyframes fadeSlideIn {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media screen and (max-width: 576px) {
  .PaymentType__alert-popup-1 {
    margin: 0;
    padding: 20px 0;
    width: 100%;
    min-height: auto;
    border-radius: 0;
    transform: translateY(100px);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .PaymentType__alert-popup-1.animation__slide_fade_in {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (min-width: 400px) {
  .PaymentType__tabs-container .slider.opt-2 {
    width: 185px;
    left: 130px;
  }
}

@media screen and (min-width: 430px) {
  .PaymentType__tabs-container .slider.opt-2 {
    width: 185px;
    left: 135px;
  }
}

@media screen and (min-width: 768px) {
  .PaymentType__heading {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }
  .PaymentType__card-2 {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1024px) {
  .PaymentType__card-2 {
    min-width: 565px;
  }
  .PaymentType__heading {
    max-width: 610px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 480px) {
  .PaymentType__heading h1 {
    color: var(--darkest-gray);
    font-size: 20px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    margin-top: 14px;
    margin-bottom: 8px;
  }
  .PaymentType_cardHeading h3 {
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 0.5em;
    border-bottom: 1px solid var(--light-silver);
    padding-bottom: 12px;
  }
  .PaymentType_cardDetailsRow strong {
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #6e6e6e;
  }
  .PaymentType__kycLink {
    width: -moz-fit-content;
    width: fit-content;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 21.79px;
    text-align: justify;
    text-decoration: none;
    color: var(--blue2);
    margin-top: 16px;
  }
  .PaymentType__tab {
    padding: 12px;
    padding-top: 0;
    gap: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    font-weight: 400;
    color: var(--darkest-gray);
    font-size: 16px;
  }
  .tab-content-heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
    color: var(--darkest-gray);
    margin-top: 20px;
  }

  .PaymentType_cardDetails td {
    color: #333;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  }
  .PaymentType__card-2 {
    margin-top: 12px;
  }
  .radio-group-payment-types {
    display: flex;
    gap: 20px;
    margin-top: 16px;
    flex-direction: column;
  }
  .PaymentType_button.md button {
    max-width: 100%;
  }
}
@media screen and (min-width: 480px) {
  .PaymentType__heading h1 {
    font-family: "MetLife Circular", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: var(--darkest-gray);
    padding: 0;
    margin: 0;
    margin-top: 14px;
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 768px) {
  .PaymentType__heading h1 {
    font-family: "MetLife Circular", sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: var(--darkest-gray);
    padding: 0;
    margin: 0;
    margin-top: 14px;
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 1024px) {
  .PaymentType__heading h1 {
    font-family: "MetLife Circular", sans-serif;
    color: var(--darkest-gray);
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    text-align: left;
    padding: 0;
    margin: 0;
    margin-top: 14px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 1024px) {
  .PaymentType_cardDetailsRow strong {
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #6e6e6e;
  }
  .PaymentType_cardDetails td {
    color: #333;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  }
  .PaymentType__kycLink {
    width: fit-content;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 21.79px;
    text-align: justify;
    text-decoration: none;
    color: var(--blue2);
    margin-top: 16px;
  }
}
@media screen and (max-width: 756px) {
  .PaymentType_cardDetails .colon {
    display: none;
  }
}
