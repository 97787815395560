.container {
  position: absolute;
  display: none;
  background: var(--light-gray);
  width: 100%;
  height: 100vh;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  z-index: 1000;
}

.innerContainer {
  display: inline-block;
  height: 100%;
  width: 85%;
}
.divider {
  margin: 20px 0;
  background-color: var(--darkest-grey);
  height: 1px;
}
.row1,
.row2 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin: 0 auto;
  gap: 4em;
}

.mega-menu-submenu ul,
.mega-menu-submenu ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mega-menu-submenu ul li {
  padding-bottom: 8px;
}

.mega-menu-heading h3 a:hover {
  text-decoration: underline;
  color: var(--dark-blue);
}
.mega-menu-heading h3 {
  padding-bottom: 10px;
  font-weight: 500;
  display: block;
  color: var(--darkest-grey);
  font-size: 14px;
}

.mega-menu-submenu a:hover {
  text-decoration: underline;
}

.mega-menu-submenu a {
  color: var(--dark-blue);
  font-size: 14px;
  padding: inherit;
  height: inherit;
  width: inherit;
  text-transform: none;
  text-decoration: none;
}

@media screen and (min-width: 576px) {
  .container {
    display: flex;
  }
}

@media screen and (min-width: 768px) {
  .innerContainer {
    width: 85%;
  }
  .row1,
  .row2 {
    gap: 10em;
  }
}

@media screen and (min-width: 922px) {
  .innerContainer {
    width: 80%;
  }
}

@media screen and (min-width: 1024px) {
  .innerContainer {
    width: 80%;
    margin-left: 3em;
  }
}
@media screen and (min-width: 1200px) {
  .innerContainer {
    margin-left: 0;
  }
}
