.container {
  position: absolute;
  display: flex;
  justify-content: center;
  background: var(--white);
  width: 100%;
  height: 100vh;
  top: 52px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  z-index: 1000;
}

.innerContainer {
  display: inline-block;
  height: 100%;
  width: 100%;
}

.row1,
.row2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mega-menu-submenu.submenu-active {
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
}

.mega-menu-submenu {
  background-color: var(--white);
  overflow: hidden;
  height: 0;
  padding: 0 30px;
  transition: all 0.2s ease-in-out;
}

.mega-menu-submenu ul,
.mega-menu-submenu ul li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.mega-menu-submenu ul li {
  padding-bottom: 8px;
}

.mega-menu-heading h3 a:hover {
  text-decoration: underline;
  color: var(--dark-blue);
}
.mega-menu-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  background: var(--light-gray);
  border-bottom: #d4d4d4 solid 1px;
  cursor: pointer;
  padding:16px;
}

.mega-menu-heading h3 {
  font-weight: 700;
  height: auto;
  padding: 4px 16px;
  display: block;
  color: var(--darkest-grey);
  font-size: 14px;
}

.mega-menu-submenu a:hover {
  text-decoration: underline;
}

.mega-menu-submenu a {
  color: var(--dark-blue);
  font-size: 14px;
  padding: inherit;
  height: inherit;
  width: inherit;
  text-transform: none;
  text-decoration: none;
}

@media screen and (min-width: 576px) {
  .container {
    display: none;
  }
}