.card {
  max-width: 580px;
}
.modalClass {
  display: flex;
  justify-content: center;
  width: 100%;
}
.primaryBtn.bothButtonVisible,
.secondaryBtn.bothButtonVisible {
  max-width: 180px;
}

.primaryBtn,
.secondaryBtn {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.07px;
  text-align: center;
}

.heading {
  margin-top: 0.5em;
}

.heading h3 {
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: var(--darkest-gray);
}
.description p {
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  padding: 0 6px;
  color: var(--darkest-gray);
}

/* Styles for screen widths above 768px */
@media screen and (min-width: 768px) {
  .heading h3 {
    font-size: 19px;
    line-height: 30px;
  }
  .description p {
    font-family: Noto Sans, Roboto, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--darkest-gray);
  }
}

.btnGroup {
  display: flex;
  width: 100%;
  justify-content: center;
  column-gap: 1em;
  margin-top: 2em;
  margin-bottom: 0.5em;
}

@media screen and (min-width: 576px) {
  .primaryBtn,
  .secondaryBtn {
    max-width: 50%;
  }
}
