.StandingInstructions__mandate-information h4 {
  font-family: "Noto Sans", sans-serif;
  padding-bottom: 10px;
  font-size: 16px;
  border-bottom: 1px solid #e5e5e5;
  font-weight: 500;
}
.Summary {
  padding-bottom: 2px;
}
.cardComponent1 {
  margin: 0px 24px;
}
.ConfirmPayment_mainContainer {
  margin-top: 16px;
}
.ConfirmPayment_cards {
  margin-top: 14px;
}
@media screen and (min-width: 480px) {
  .StandingInstructions__mandate-information h4 {
    border-bottom: 0px;
    font-weight: 700;
  }
  .ConfirmPayment_cards {
    margin-top: 0px;
  }
}
@media screen and (min-width: 481px) {
  .ConfirmPayment_mainContainer {
    display: flex;
    width: 82%;
    margin: auto;
    justify-content: center;
  }
  .Confirm_payment {
    margin: 0;
    padding: 0;
  }
  .cardComponent1 {
    margin: 24px;
    margin-top: 5px;
  }
}
@media screen and (min-width: 576px) {
  .ConfirmPayment_mainContainer {
    display: flex;
    width: 75%;
    margin: auto;
    justify-content: center;
  }
  .Confirm_payment {
    margin: 0;
    padding: 0;
  }
}
h3 {
  margin: 0px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 12px;
}
.cardComponent {
  margin: 12px 24px;
}

.card_policyDetails_info {
  display: flex;
  justify-content: flex-start;
  padding-top: 16px;
  /* align-items: center; */
}
.policyDetails_sections {
  flex: 1 0 0;
}
.policyDetails_sections div {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}
.policyDetails_sections div label {
  color: var(--dark-gray);
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.policyDetails_sections div small {
  color: var(--darkest-grey);
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* .card_payableAmount_DropDown {
  padding: 20px 0px;
} */
.SelectAmountContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
  width: 100%;
  min-height: 90px;
}
.ConfirmPayment_button {
  column-gap: 10px;
  margin: 20px 0px;
  padding: 0 1.3em;
  display: flex;
  justify-content: space-between;
}

.ConfirmPayment__payment-gateways .cf-payment__gateway label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ConfirmPayment__payment-gateways .cf-payment__gateway label span {
  text-transform: none;
}
.ConfirmPayment__payment-gateways .cf-payment__gateway img {
  object-fit: contain;
  height: 64px;
  width: 130px;
}

.ConfirmPayment__payment-gateways {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 0;
  row-gap: 10px;
  justify-content: flex-start;
  margin: 20px -16px;
}

.ConfirmPayment_heading {
  padding: 0 20px;
}
.ConfirmPayment_heading h1 {
  font-family: "MetLife Circular", sans-serif;
  color: var(--darkest-gray);
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  padding: 0;
  margin: 0;
}

.ConfirmPayment__payment-gateways .cf-payment__gateway {
  transition: all 0.2s ease-in-out;
  height: 70px;
  width: 100%;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 10px;
  background: var(--white);
}

.ConfirmPayment__dropdown-custom {
  width: 100% !important;
  margin-top: 2px;
}

.ConfirmPayment__input-custom {
  width: 49%;
}
.ConfirmPayment_itemComponent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
}
.ConfirmPayment_InputDropDown__item {
  justify-content: space-between;
  display: flex;
  padding: 10px 18px;
  cursor: pointer;
}
.ConfirmPayment_InputDropDown__item.active {
  color: var(--blue2);
  font-weight: 600;
}

.ConfirmPayment_InputDropDown__Selected + div {
  top: 2px !important;
}
.ConfirmPayment_cardContainer .is-error {
  color: #dd2224;
}
.ConfirmPayment_InputDropDown__Selected {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-right: 18px;
}

.ConfirmPayment__payment-gateways-disabled:hover,
.ConfirmPayment__payment-gateways-disabled:focus,
.ConfirmPayment__payment-gateways-disabled img {
  pointer-events: none !important;
  cursor: not-allowed !important;
  filter: grayscale(1) !important;
  border-color: transparent !important;
}

.StandingInstructions__mandate-information input[type="number"],
.ConfirmPayment__otheramount-custom input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.StandingInstructions__mandate-information
  input[type="number"]::-webkit-inner-spin-button,
.StandingInstructions__mandate-information
  input[type="number"]::-webkit-outer-spin-button,
.ConfirmPayment__otheramount-custom input[type="number"]::-webkit-inner-spin-button,
.ConfirmPayment__otheramount-custom input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

.ConfirmPayment__otheramount-custom {
  width: 100%;
}

.ConfirmPayment__otheramount-custom.has-error {
  margin-bottom: -10px;
}

/* Standing Instructions */

.StandingInstructions__mandate-information {
  width: 100%;
  min-width: 70vw;
}

.StandingInstructions__mandate-information .row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25em;
  gap: 1.25em;
}
.StandingInstructions__mandate-information .row .value,
.StandingInstructions__mandate-information .row .value1 {
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 29.96px;
  text-align: left;
  color: var(--darkest-gray);
}
.StandingInstructions__mandate-information .row .label {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.52px;
  text-align: left;
  color: var(--dark-gray);
}
.update-policyOwnerMobileNumber {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.update-policyOwnerMobileNumber button {
  padding: 0;
  margin: 0;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}

@media screen and (max-width: 390px) {
  .update-mobile-modal-content .modal-body {
    padding: 16px 0px;
  }
}
.update-mobile-modal-content .modal-body {
  padding: 24px;
}

.update-mobile-modal-content .modal-body .modal-body-heading h3 {
  font-family: "Noto Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: var(--darkest-gray);
}

.update-mobile-modal-content .modal-body .modal-body-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
  align-items: center;
  margin-top: 0px;
}

.update-mobile-modal-content .modal-body .modal-body-content {
  padding: 26px 0;
}

.StandingInstructions__bottom-notice {
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.34px;
  text-align: left;
  color: var(--dark-gray);
  padding: 0 24px;
}

.update-mobile-modal-content {
  min-width: 80vw;
  min-height: 20vh;
}

.ConfirmPayment_page-inner-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  padding-top: 0px;
}
.ConfirmPayment_page-inner-header button {
  padding: 0;
  margin: 0;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: right;
  color: var(--blue2);
  text-decoration: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  display: none;
}

/* Deduct Suspense amount css */

.payment-summary-details .row:first-child {
  padding: 12px 0px 14px 0px; /* Adjust the padding value as needed */
}
.payment-summary-details .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}

.payment-summary-details .row.final {
  padding-top: 12px;
  border-top: #e5e5e5 1px solid;
}
.payment-summary-details .row.final .label,
.payment-summary-details .row.final .value {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 29.96px;
  text-align: left;
  color: var(--darkest-gray);
}

.payment-summary-details .row .label {
  font-family: "Noto Sans", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 29.96px;
  text-align: left;
  /* color: var(--darkest-gray); */
  color: #6e6e6e;
}

/* Deduct Suspense amount css */

@media screen and (min-width: 576px) {
  .ConfirmPayment_page-inner-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  .ConfirmPayment__payment-gateways {
    margin: 20px 0;
  }
  .update-mobile-modal-content .modal-body .modal-body-footer {
    flex-direction: row;
  }
  .update-mobile-modal-content .modal-body .modal-body-footer button {
    max-width: 150px;
  }
  .update-mobile-modal-content {
    min-width: 90vw;
    min-height: 20vh;
  }
}

@media screen and (min-width: 480px) {
  h3 {
    border-bottom: 0px;
  }
  .SelectAmountContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
    width: 100%;
    min-height: 130px;
  }
  .payment-summary-details .row:first-child {
    padding: 0px 0px 14px 0px; /* Adjust the padding value as needed */
  }
  .payment-summary-container .cardHeading {
    /* margin-bottom: 16px; */
    padding-bottom: 16px;
    border: 0px;
  }
  .ConfirmPayment_button button {
    max-width: 180px;
  }
  .ConfirmPayment_page-inner-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    padding-top: 0px;
  }
  .cardComponent {
    margin: 12px 24px;
  }
}

/* Standing Instructions */
@media screen and (min-width: 768px) {
  .ConfirmPayment_button button {
    max-width: 145px;
  }
  .ConfirmPayment__otheramount-custom {
    width: 48%;
  }
  .ConfirmPayment__dropdown-custom {
    width: 49% !important;
  }

  .SelectAmountContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .update-mobile-modal-content {
    min-width: 60vw;
  }

  .ConfirmPayment__pd-content-wrap .form-control input[type="email"] {
    display: block;
    max-width: 60%;
  }
  .StandingInstructions__mandate-information .row {
    flex-direction: row;
    column-gap: 2em;
  }
  .StandingInstructions__mandate-information .row .column-1,
  .StandingInstructions__mandate-information .row .column-2 {
    width: 50%;
  }
  .ConfirmPayment__button-flex-end button {
    max-width: 120px;
  }
  .payment-summary-details .row .label {
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 29.96px;
    text-align: left;
    color: var(--darkest-gray);
  }
  .payment-summary-details .row.final .label,
  .payment-summary-details .row.final .value {
    font-family: "Noto Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 29.96px;
    text-align: left;
    color: var(--darkest-gray);
  }
  .payment-summary-container .cardHeading {
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 922px) {
  .update-mobile-modal-content {
    min-width: 60vw;
  }

  .ConfirmPayment__pd-content-wrap .form-control input[type="email"] {
    display: block;
    max-width: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .cardComponent {
    margin: 24px 0;
  }
  .cardComponent1 {
    margin: 24px 0;
  }
  .ConfirmPayment_heading,
  .ConfirmPayment_button {
    padding: initial;
  }

  .ConfirmPayment__payment-gateways {
    gap: 20px;
  }
  .ConfirmPayment__payment-gateways .cf-payment__gateway {
    width: 14%;
    padding: 16px;
    border: 1px solid var(--Gray---Lightest, var(--light-gray));
  }

  .ConfirmPayment__payment-gateways .cf-payment__gateway.active {
    border-color: var(--blue1);
  }

  .ConfirmPayment__payment-gateways .cf-payment__gateway:hover,
  .ConfirmPayment__payment-gateways .cf-payment__gateway:focus {
    border-color: var(--blue1);
  }

  .ConfirmPayment__payment-gateways .cf-payment__gateway label {
    justify-content: center;
  }

  .ConfirmPayment__payment-gateways .cf-payment__gateway label span {
    display: none;
  }
  .update-policyOwnerMobileNumber {
    max-width: 200px;
  }
  .update-mobile-modal-content {
    min-width: 45vw;
  }
}

@media screen and (min-width: 1200px) {
  .update-mobile-modal-content {
    min-width: 35vw;
  }

  .update-policyOwnerMobileNumber {
    max-width: 200px;
  }
}
@media screen and (min-width: 1500px) {
  .update-mobile-modal-content {
    min-width: 25vw;
  }
  .StandingInstructions__mandate-information {
    width: 100%;
    min-width: 50vw;
  }
}
@media screen and (max-width: 767px) {
  h3 {
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
  .StandingInstructions__mandate-information .row .label {
    color: #6e6e6e;
    font-family: "Noto Sans", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .StandingInstructions__mandate-information .row .value1 div {
    text-align: left;
    min-width: 46vw;
  }
  .StandingInstructions__mandate-information .row .value div {
    text-align: left;
    min-width: 46vw;
  }
  .StandingInstructions__mandate-information .input-container {
    width: 100%;
  }
  .StandingInstructions__mandate-information .row .value,
  .StandingInstructions__mandate-information .row .value1 {
    color: #333;
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }
  .StandingInstructions__mandate-information .row .column-1,
  .StandingInstructions__mandate-information .row .column-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 6vw;
    justify-content: space-between;
  }
}
@media screen and (max-width: 767px) {
  .StandingInstructions__mandate-information .row .value div {
    text-align: left;
    min-width: 46vw;
  }
}
@media screen and (max-width: 480px) {
  .StandingInstructions__mandate-information .row .value div {
    text-align: left;
    min-width: 59vw;
  }
}

@media screen and (max-width: 576px) {
  .cf-custom-modal-wrapper-bottom-sheet.animation__slide_fade_in,
  .cf-custom-modal-wrapper.animation__slide_fade_in {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .ConfirmPayment_heading h1 {
    font-family: "MetLife Circular", sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    /* margin-top: 16px; */
  }
  .cf-custom-modal-wrapper-bottom-sheet,
  .cf-custom-modal-wrapper {
    margin: 0;
    padding: 20px 0;
    width: 100%;
    min-height: auto;
    border-radius: 0;
    transform: translateY(500px);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
  }
  .SelectPaymentAmount__bottomsheet {
    padding: 16px;
  }
  .SelectPaymentAmount__bottomsheet .heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-bottom: 8px;
  }
  .SelectPaymentAmount__bottomsheet .ConfirmPayment_InputDropDown__item {
    padding: 0;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    border-bottom: var(--light-gray) 2px solid;
  }
  .ConfirmPayment_InputDropDown__item div.otherValue {
    color: var(--darkest-gray);
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: left;
  }
  .ConfirmPayment_InputDropDown__item.active div.premiumValue {
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    text-align: left;
  }
  .ConfirmPayment_InputDropDown__item.active div.otherValue,
  .ConfirmPayment_InputDropDown__item.active div.premiumValue,
  .ConfirmPayment_InputDropDown__item.active .premium_amount {
    color: var(--blue2) !important;
  }

  .ConfirmPayment_InputDropDown__item .premium_amount {
    color: var(--darkest-gray);
    font-family: "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 21.79px;
    text-align: left;
  }
  .ConfirmPayment_InputDropDown__Selected strong {
    color: var(--darkest-gray);
    font-weight: 600;
    font-size: 16px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .cf-custom-modal-wrapper.animation__slide_fade_in {
    opacity: 1;
    transform: translateY(421px);
  }

  .cf-custom-modal-wrapper-bottom-sheet.animation__slide_fade_in {
    opacity: 1;
    transform: translateY(335px);
  }
}
@media screen and (min-width: 480px) {
  .ConfirmPayment_heading h1 {
    font-family: "MetLife Circular", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 8px;
  }
}
.ConfirmPayment_page-inner-header.tablet button {
  display: block !important;
}
@media screen and (min-width: 768px) {
  .ConfirmPayment_page-inner-header.tablet {
    display: none;
  }
  .ConfirmPayment_heading h1 {
    font-family: "MetLife Circular", sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 48px;
    text-align: left;
  }
  .ConfirmPayment_page-inner-header button {
    display: initial;
  }
}

@media screen and (min-width: 1024px) {
  .ConfirmPayment_heading h1 {
    font-family: "MetLife Circular", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 48px;
    text-align: left;
    margin-bottom: 0px;
  }
}

.btn-SI-cancellation {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.79px;
  text-align: right;
  color: var(--blue2);
}
