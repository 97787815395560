.otpInputsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.container {
  display: initial;
}
.otpInputsContainer input {
  width: 40px;
  height: 40px;
  text-align: center;
  outline: none;
  border: none;
  border-bottom: 2px solid var(--blue2);
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 20px;
  font-weight: 400;
  caret-color: var(--blue2);
  line-height: 38.14px;
}

.otpInputsContainer.is-invalid input {
  border-bottom-color: var(--red);
}

.is-error {
  color: var(--red);
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  margin-top: 14px;
}

/* Above Mobile Sizing */
@media screen and (min-width: 480px) {
  .otpInputsContainer input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    line-height: 48px;
  }
}
@media screen and (min-width: 576px) {
  .otpInputsContainer input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    line-height: 48px;
  }
}
@media screen and (min-width: 768px) {
  .otpInputsContainer input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    line-height: 48px;
  }
}
