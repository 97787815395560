@import "./fonts.css";
/* Color Palette Variables */
@import "./colors.css";

html {
  height: 100%;
}

/* min-width is 375px for extremly small screen sized devices. */
body {
  padding: 0;
  margin: 0;
  width: 100%;
  min-width: 375px;
  height: 100%;
  /* default font */
  font-family: "Noto Sans", sans-serif;
  font-weight: normal;
  color: var(--dark);
  position: relative;
  background-color: var(--white);
}

.capitalize-text {
  text-transform: capitalize;
}

/* Form Control */

.form-control {
  margin-bottom: 0.5em;
}

.form-control,
.form-control input[type="text"],
.form-control input[type="number"],
.form-control input[type="email"],
.form-control label {
  width: 100%;
}

.form-control label {
  display: block;
  font-size: 15px;
  font-weight: 500;
}

.form-control input[type="text"],
.form-control input[type="number"],
.form-control input[type="email"] {
  height: 30px;
  margin: 5px 0;
  text-indent: 8px;
}

.form-control input[type="number"]::-webkit-outer-spin-button,
.form-control input[type="number"]::-webkit-inner-spin-button {
  display: none;
}