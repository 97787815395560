.Modal__container {
  display: flex; /* Hidden by default */
  justify-content: flex-end;
  align-items: baseline;
  flex-direction: column;
  position: absolute; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.7); /* Black w/ opacity */
}
.Modal__header__title {
  font-size: 22px;
  font-weight: 800;
  color: var(--extradark);
}
.Modal__header {
  padding: 0 16px;
  padding-top: 8px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light-grey);
}
.closeIcon:hover {
  color: var(--black);
}
.closeIcon {
  cursor: pointer;
  color: var(--grey);
  font-size: 2em;
  font-weight: 500;
}

.Modal__wrapper {
  width: 100%;
  border-radius: 0;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  opacity: 0;
  position: relative;
  background-color: #fefefe;
  z-index: 10;
  border: none;
  outline: none;
  overflow: hidden;
  transform: translateY(10vh);
  transition: all 0.2s ease-in-out;
}

.animation__slide_fade_in {
  opacity: 1 !important;
  transform: translateY(0px);
}
.animation__slide_fade_out {
  opacity: 0 !important;
}

.Modal__content {
  /* padding: 0 16px; */
  min-width: 375px;
}

.Modal__container [data-class-identifier="alert-btn-group"] {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.Modal__container [data-component="card"] {
  padding: 24px 0px;
  width: 90%;
}

@media screen and (min-width: 480px) {
  .Modal__container [data-component="alert-popup"] {
    justify-content: flex-start;
    max-width: 100%;
  }

  .Modal__container [data-component="card"] {
    padding: 24px 16px;
    justify-content: flex-start;
    max-width: 100%;
    width: 100%;
  }

  .Modal__container [data-class-identifier="alert-btn-group"] {
    flex-direction: row;
    /* justify-content: center; */
  }

  .Modal__container
    [data-class-identifier="alert-btn-group"]
    button:not([data-single-btn="true"]) {
    max-width: 163px;
  }
}

@media screen and (min-width: 768px) {
  .Modal__container [data-class-identifier="alert-btn-group"] {
    flex-direction: row;
    justify-content: center;
    margin: initial;
    width: 100%;
  }
  .Modal__container [data-component="card"] {
    justify-content: flex-start;
    max-width: 100%;
    width: 100%;
  }
  .Modal__container [data-single-btn="true"] {
    max-width: 180px;
  }
  .Modal__wrapper {
    border-radius: 18px;
    width: auto;
    margin: auto;
    transform: translateY(0);
    top: -10px;
  }

  .animation__slide_fade_in {
    opacity: 1 !important;
    top: 0 !important;
  }
  .animation__slide_fade_out {
    opacity: 0 !important;
    top: -10px !important;
  }
  .Modal__container [data-component="alert-popup"] {
    justify-content: flex-start;
    max-width: 70vw;
  }
}
@media screen and (min-width: 1024px) {
  .Modal__container [data-component="alert-popup"] {
    justify-content: flex-start;
    max-width: 604px;
  }
}
