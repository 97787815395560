.container {
  width: 100%;
  background-color: var(--white);
}

.innerContainer {
  padding: 32px 0px;
}

.content {
  max-height: 74vh;
  padding: 0 24px;
  padding-right: 0;
  overflow-y: auto;
  margin-right: 15px;
}

.content::-webkit-scrollbar {
  width: 5px;
}

.content::-webkit-scrollbar-track {
  background: transparent;
}

.content::-webkit-scrollbar-thumb {
  background: var(--medium-gray);
  border-radius: 5px;
}

.heading {
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 12px;
}
.agreePill {
  border-radius: 8px;
  background: #008a80;
  align-items: center;
  padding: 3px 6px;
}
.agreePill small {
  color: #ebebeb;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
}
.subHeading {
  padding-top: 12px;
}
.subHeading h4 {
  margin: 0;
  padding: 0;
  font-family: "Noto Sans", sans-serif;
  color: var(--darkest-gray);
  padding-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.79px;
  text-align: left;
}

.subHeading span {
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 21.79px;
  text-align: left;
  color: var(--darkest-gray);
}

.heading h3 {
  font-family: "Noto Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  padding: 0;
  margin: 0;
}
.privacyTerms ul {
  padding: 0 24px;
}
.privacyTerms ul li::before {
  position: relative;
  content: " "; /* bullet point */
  display: inline-block;
  font-size: 20px;
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: var(--darkest-gray);
  margin-left: -8px;
  left: -10px;
  top: -1px;
  vertical-align: middle; /* adjust as needed */
}
.privacyTerms ul li {
  color: var(--darkest-gray);
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  padding-bottom: 16px;
  list-style-type: none;
}

.innerContainer .footer {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  padding-top: 30px;
  border-top: var(--light-grey) solid 1px;
}

.innerContainer .footer button {
  max-width: 163px !important;
}

@media screen and (min-width: 480px) {
  .innerContainer .footer button {
    max-width: 173px !important;
  }
}

@media screen and (min-width: 768px) {
  .content {
    max-height: 50vh;
  }
  .container {
    height: 70vh;
    width: 75vw;
  }

  .innerContainer .footer {
    padding-top: 15px;
    margin-top: 20px;
    border-top: var(--light-grey) solid 1px;
  }
}
@media screen and (min-width: 922px) {
  .container {
    height: 70vh;
    width: 70vw;
  }
}
@media screen and (min-width: 1024px) {
  .container {
    height: 70vh;
    width: 55vw;
  }
}
