.toast {
  position: fixed;
  display: flex;
  border-radius: 8px;
  padding: 16px;
  top: -50px;
  transition: all 0.1s ease-in-out;
  box-shadow: 0px 0px 5px 0px #0000001a;
  z-index: 100;
  left: 8%;
  opacity: 0;
}
.toast .content .iconmessage {
  display: flex;
  gap: 12px;
}
.toast .content {
  display: flex;
  width: 80vw;
  align-items: center;
  justify-content: space-between;
  align-self: center;
}
.toast.show {
  top: 40px;
  opacity: 1;
}

.toast.success {
  background-color: #e5f7f5;
}

@media screen and (min-width: 576px) {
  .toast {
    left: 13%;
  }
  .toast .content {
    width: 70vw;
  }
}

@media screen and (min-width: 768px) {
  .toast {
    left: 18%;
  }
  .toast .content {
    width: 60vw;
  }
}

@media screen and (min-width: 1024px) {
  .toast {
    left: 23%;
  }
  .toast .content {
    width: 50vw;
  }
}

@media screen and (min-width: 1024px) {
  .toast {
    left: 25%;
  }
  .toast .content {
    width: 40vw;
  }
}

@media screen and (min-width: 1200px) {
  .toast {
    left: 30%;
  }
  .toast .content {
    width: 30vw;
  }
}
