.App__main {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--light-grey);
}
.animation__slide_fade_in {
  opacity: 1 !important;
  top: 0 !important;
}
.animation__slide_fade_out {
  opacity: 0 !important;
  top: -10px !important;
}
.AppHeader__container__scrolled {
  box-shadow: var(--gray) 1px 1px 8px;
}
.contact-us svg {
  width: 20px;
  margin-right: 5px;
}
.contact-us {
  display: flex;
  align-items: center;
}
.AppHeader__container {
  position: sticky;
  top: 0;
  background-color: var(--white);
  z-index: 20;
}
.search-dialog-box_mobile {
  background-color: var(--light-grey);
  padding: 10px 12px 10px 12px;
}
.search-dialog-box_mobile form {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1.5px solid var(--dark-blue);
  background-color: white;
}
.search-icon-mobileView button {
  background: none;
  box-shadow: none;
  border: 0px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  color: var(--dark-blue);
  padding-right: 8px;
}
.search-icon-mobileView button:hover {
  cursor: pointer;
}
.search-dialog-box_mobile form input {
  width: 100%;
  border: none;
  outline: none;
  height: 30px;
}
.search-dialog-box {
  display: none;
}
.App__fluid-container {
  width: 100%;
  margin: 0 auto;
}

.AppHeader__wrapper {
  padding: 14px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AppHeader__wrapper a {
  cursor: pointer;
  text-decoration: none;
  color: var(--darkest-grey);
}

.AppHeader_leftLinks {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.AppHeader_leftLinks a:hover span,
.AppHeader_leftLinks a:hover svg {
  color: var(--dark-blue) !important;
  fill: var(--dark-blue) !important;
}

.AppHeader_leftLinks a svg {
  position: relative;
  top: 0px;
}

.AppHeader_leftLinks a span {
  margin-left: 5px;
}

.AppHeader_leftLinks a.top-menu.home,
.AppHeader_leftLinks a.top-menu span {
  display: none;
}

.AppHeader_leftLinks a.top-menu {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
}
.AppHeader_rightLinks {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  column-gap: 25px;
}

.AppHeader_rightLinks a.top-menu span {
  font-size: 14px;
  text-transform: uppercase;
}

.AppHeader__logo svg {
  height: 22px;
  width: 100%;
  object-fit: contain;
}
.App__pageContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 0px;
  margin-bottom: 20px;
}
.AppContainer__page-title-container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--white);
  background-image: url("../assets/images/Background.png");
  background-size: cover;
}

.App__pageSubTitle {
  display: none;
  font-family: "MetLife Circular", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.App__pageTitle {
  font-family: "MetLife Circular", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  padding-left: 4.5vw;
  text-align: left;
}

.breadcrumb {
  display: none;
  background-color: var(--white);
  color: var(--darkest-grey);
  padding: 8px 16px;
  border-radius: 20px;
  max-width: max-content;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  margin-bottom: 40px;
}
.breadcrumb span.arrow {
  margin: 0 0.5em;
}
.breadcrumb span.pagename,
.breadcrumb span.title {
  color: var(--black);
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 1px;
  text-align: left;
}

.AppHeader__logo {
  margin-left: -3%;
}
@media screen and (min-width: 480px) {
  .App__pageTitle {
    font-family: "MetLife Circular", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    padding-left: 7.2vw;
    text-align: left;
  }
  .OTP_Title {
    padding-left: 9.2vw;
  }
  .App__pageContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
  }
}

@media screen and (min-width: 576px) {
  .AppHeader_leftLinks {
    column-gap: 25px;
  }
  .App__pageTitle {
    font-family: "MetLife Circular", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    padding-left: 9vw;
    text-align: left;
  }
  .OTP_Title {
    padding-left: 13.2vw;
  }
}

@media (max-width: 768px) {
  .AppHeader_rightLinks a.top-menu.contact-us span {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .App__main {
    background-color: var(--light-grey);
  }

  .search-dialog-box_mobile {
    display: none;
  }

  .search-dialog-box {
    border: 1.5px solid var(--dark-blue);
    background: white;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .search-dialog-box .search-icon button {
    background: none;
    box-shadow: none;
    border: 0px;
    display: flex;
    justify-content: center;
    color: var(--dark-blue);
    padding-right: 8px;
  }
  .search-dialog-box .search-icon button:hover {
    color: var(--dark-blue);
    cursor: pointer;
  }
  .search-dialog-box input {
    border: none;
    outline: none;
    padding-left: 8px;
    padding-right: 8px;
    height: 32px;
    width: 140px;
  }
}

@media screen and (min-width: 768px) {
  .App__pageTitle {
    font-family: "MetLife Circular", sans-serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    padding-left: 14vw;
    text-align: left;
  }
}

@media screen and (min-width: 769px) {
  .App__pageContent {
    align-items: center;
    justify-content: center;
  }
  .AppHeader_rightLinks a.top-menu.contact-us,
  .AppHeader_leftLinks a.top-menu.search,
  .AppHeader_leftLinks a.top-menu.home,
  .AppHeader_leftLinks a.top-menu span {
    display: flex;
  }

  .AppHeader_leftLinks a.top-menu.home svg,
  .AppHeader_leftLinks a.top-menu.search svg {
    top: -2px;
  }

  .App__fluid-container {
    max-width: 98%;
  }
  .App__pageTitle {
    font-family: "MetLife Circular", sans-serif;
    font-size: 34px;
    font-weight: 500;
    line-height: 54px;
    text-align: left;
    padding-left: 0px;
  }

  .App__main {
    height: 100%;
  }
  .AppContainer__page-title-container {
    padding: 2.5em 4em;
  }
  .AppHeader__wrapper {
    padding: 16px 24px;
  }
  .breadcrumb,
  .App__pageSubTitle {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 1024px) {
  .App__pageTitle {
    font-family: "MetLife Circular", sans-serif;
    font-size: 44px;
    font-weight: 500;
    line-height: 54px;
    text-align: left;
  }
}
@media screen and (min-width: 1200px) {
  .AppHeader__logo {
    margin-left: -10%;
  }

  .AppHeader__wrapper {
    padding: 16px 0;
  }
  /* 
  .AppContainer__page-title-container {
    padding: 2.5em 7em;
  } */
}
@media screen and (min-width: 1500px) {
  /* .App__fluid-container {
    max-width: 70%;
  } */
}
@media screen and (max-width: 480px) {
  .App__main {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--white);
  }
}
