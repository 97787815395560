.VerifyOTP__mainContainer {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-control {
  margin-bottom: 0.5em;
}

.VerifyOTP__wrapper {
  width: 100%;
  margin: auto;
}

.VerifyOTP__heading {
  margin-bottom: 16px;
  padding: 0 16px;
}

.VerifyOTP__heading h1 {
  font-family: "MetLife Circular", sans-serif;
  color: var(--darkest-gray);
  font-size: 20px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  line-height: 32px;
  margin-top: 30px;
  margin-bottom: 8px;
  margin-left: 3px;
}
.VerifyOTP__heading small {
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--dark-grey);
}

.VerifyOTP__submit-button-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  /* flex-direction: column; */
  gap: 10px;
  padding: 0 22px;
  margin-bottom: 10px;
}
.VerifyOTP__otp-resend-section.timer-active div {
  display: inline;
}

.VerifyOTP__otp-resend-section.timer-active button {
  pointer-events: none;
  cursor: initial;
  color: var(--medium-grey) !important;
}

.VerifyOTP__otp-input-header h4 {
  font-family: "Noto-Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  margin-top: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #d9d9d9;
}
.VerifyOTP__otp-input-header p {
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--dark-grey);
  padding-bottom: 9px;
}
.VerifyOTP__otp-resend-section button:hover {
  color: var(--dark-blue);
}
.VerifyOTP__otp-resend-note a {
  color: var(--blue2);
  text-decoration: none;
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
}
.VerifyOTP__otp-resend-note {
  background-color: var(--light-grey);
  padding: 20px 16px;
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--dark-grey);
  border-radius: 8px;
}

.VerifyOTP__otp-resend-section div {
  display: none;
}
.VerifyOTP__otp-resend-section button {
  outline: none;
  border: none;
  background-color: transparent;
  height: auto;
  width: auto;
  margin: 0;
  padding: 0;
  color: var(--blue2);
  cursor: pointer;
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
}
.VerifyOTP__otp-resend-section {
  display: flex;
  gap: 6px;
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: var(--medium-grey);
}
.VerifyOTP__form-card {
  margin: auto;
  width: 78%;
  border-radius: 15px;
}
.VerifyOTP__back-button {
  display: none;
}
@media screen and (min-width: 480px) {
  .VerifyOTP__heading {
    margin-bottom: 16px;
    padding: 0 22px;
  }
  .VerifyOTP__back-button {
    display: inline;
  }
  .VerifyOTP__mainContainer {
    margin: auto;
    width: 90%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px;
  }
  .VerifyOTP__submit-button-container {
    margin-top: 20px;
    display: flex;
    align-items: center;
    /* flex-direction: column; */
    gap: 90px;
    padding: 0 22px;
    margin-bottom: 10px;
  }

  .VerifyOTP__heading h1 {
    font-family: "MetLife Circular", sans-serif;
    color: var(--darkest-gray);
    font-size: 24px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    line-height: 32px;
    margin-top: 18px;
    margin-bottom: 8px;
    margin-left: 6px;
  }
}
/* Above Mobile Sizing */
@media screen and (min-width: 576px) {
  .VerifyOTP__heading {
    padding: 0 22px;
  }
  .VerifyOTP__submit-button-container {
    flex-direction: row;
    justify-content: space-between;
    column-gap: 28px;
    padding: 0 25px;
  }

  .VerifyOTP__submit-button {
    max-width: 145px;
  }
  .VerifyOTP__wrapper {
    width: auto;
    max-width: 89vw;
  }
  .VerifyOTP__form-card {
    margin: auto;
    /* width: 90%; */
    border-radius: 15px;
  }
  .VerifyOTP__mainContainer {
    margin: auto;
    width: 90%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* Above Mobile Sizing to Tab Sizing */
@media screen and (min-width: 768px) {
  .VerifyOTP__heading {
    padding: 0 30px;
  }
  .VerifyOTP__form-card {
    padding: 24px 32px;
  }
  .VerifyOTP__heading h1 {
    font-family: "MetLife Circular", sans-serif;
    color: var(--darkest-gray);
    font-size: 28px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    line-height: 32px;
    margin-top: 24px;
    margin-bottom: 8px;
    margin-left: 5px;
  }
  .VerifyOTP__mainContainer {
    margin: auto;
    width: 75%;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* Above Tab Sizing to Notebook Sizing */
@media screen and (min-width: 922px) {
  .VerifyOTP__wrapper {
    width: auto;
    max-width: 60vw;
  }
  .VerifyOTP__mainContainer {
    margin: auto;
    min-height: 40vh;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* Above Notebook Sizing to Laptop Sizing */
@media screen and (min-width: 1024px) {
  .VerifyOTP__heading small {
    display: none;
  }
  .VerifyOTP__wrapper {
    width: auto;
    max-width: 564px;
  }
  .VerifyOTP__heading h1 {
    text-align: left;
    font-family: "MetLife Circular", sans-serif;
    color: var(--darkest-gray);
    font-size: 32px;
    font-weight: 500;
    padding: 0;
    margin: 0;
    line-height: 32px;
    margin-top: 30px;
    margin-bottom: 12px;
    margin-left: 5px;
  }
}
@media screen and (max-width: 480px) {
  .VerifyOTP__form-card {
    width: 87%;
    margin: auto;
    border-radius: 0;
    padding: 0px 2px;
  }
}
