.PolicyDetails__mainContainer {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-control {
  margin-bottom: 0.5em;
}

.PolicyDetails__wrapper {
  width: auto;
  padding: 0 25px;
  margin: auto;
}

.PolicyDetails__heading {
  margin-bottom: 16px;
}

.PolicyDetails__heading h1 {
  font-family: "MetLife Circular", sans-serif;
  color: var(--darkest-gray);
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 8px;
}
@media (min-width: 480px) and (max-width: 768px) {
  .PolicyDetails__heading h1 {
    font-size: 24px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .PolicyDetails__heading h1 {
    font-size: 28px;
  }
}

@media (min-width: 1025px) {
  .PolicyDetails__heading h1 {
    font-size: 28px; /* Default for screens wider than 1024px */
  }
}
.PolicyDetails__heading small {
  font-family: Noto Sans, Roboto, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--dark-grey);
}
.PolicyDetails__submit-button-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
/* Above Mobile Sizing */
@media screen and (min-width: 576px) {
  .PolicyDetails__wrapper {
    width: auto;
    max-width: 80vw;
  }
}
@media screen and (min-width: 480px) {
  .PolicyDetails__form-card {
    padding: 24px 32px;
  }

  .PolicyDetails__submit-button {
    max-width: 120px;
  }
  .PolicyDetails__submit-button-container {
    justify-content: flex-start;
  }
  .PolicyDetails__wrapper {
    width: 80%;
    max-width: 88vw;
  }
}
@media screen and (min-width: 576px) {
  .PolicyDetails__form-card {
    padding: 24px 32px;
  }

  .PolicyDetails__submit-button {
    max-width: 180px;
  }
  .PolicyDetails__submit-button-container {
    justify-content: flex-start;
  }
  .PolicyDetails__wrapper {
    width: 72%;
    max-width: 88vw;
  }
}

/* Above Mobile Sizing to Tab Sizing */
@media screen and (min-width: 768px) {
  .PolicyDetails__form-card {
    padding: 24px 32px;
  }

  .PolicyDetails__submit-button {
    max-width: 180px;
  }
  .PolicyDetails__submit-button-container {
    justify-content: flex-end;
  }
  .PolicyDetails__wrapper {
    width: 65%;
    max-width: 70vw;
  }
}

/* Above Tab Sizing to Notebook Sizing */
@media screen and (min-width: 922px) {
  .PolicyDetails__wrapper {
    width: 65%;
    max-width: 55vw;
  }
}

/* Above Notebook Sizing to Laptop Sizing */
@media screen and (min-width: 1024px) {
  .PolicyDetails__heading h1 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
  }
  .PolicyDetails__heading small {
    display: none;
  }
  .PolicyDetails__submit-button-container {
    justify-content: flex-end;
  }
  .PolicyDetails__wrapper {
    width: 65%;
    max-width: 45vw;
  }
}

/* Above Laptop Sizing to Large Devices */
@media screen and (min-width: 1200px) {
  .PolicyDetails__wrapper {
    width: 65%;
    max-width: 40vw;
  }
}

/*  Large Devices */
@media screen and (min-width: 1500px) {
  .PolicyDetails__wrapper {
    width: 65%;
    max-width: 30vw;
  }
}

.grecaptcha-badge {
  display: none !important;
}

.idle-user-alert {
  background-color: rgba(0, 123, 189, 0.188);
  padding: 20px 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.idle-user-alert,
.idle-user-alert .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 16px;
}

.idle-user-alert button {
  background-color: transparent;
  outline: none;
  border: none;
}
.toast-message .close-icon:hover {
  color: var(--darkest-gray);
}

.toast-message .close-icon {
  position: relative;
  top: -1em;
  right: -0.4em;
  color: #666666;
}

.toast-message {
  color: #003c70;
  background-color: #dff0ff;
  height: auto;
  margin-bottom: 1em;
  padding: 16px;
  border-radius: 12px;
  box-shadow: rgb(0 0 0 / 19%) 0px 0px 3px;
}

.toast-message .content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
}

@media screen and (max-width: 576px) {
  .PolicyDetails__alert-popup-1.animation__slide_fade_in {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .PolicyDetails__alert-popup-1 {
    margin: 0 !important;
    padding: 16px 0;
    width: 100%;
    min-height: auto;
    border-radius: 0 !important;
    transform: translateY(400px);
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .PolicyDetails__alert-popup-1.animation__slide_fade_in {
    opacity: 1;
    transform: translateY(310px);
  }
}

@media screen and (max-width: 480px) {
  .PolicyDetails__wrapper {
    width: auto;
    padding: 0 10px;
    margin: 0 0;
  }
  .PolicyDetails__mainContainer {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px 20px 10px;
  }
}
