.gradientRow {
  display: none;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, var(--blue2) 0%, var(--green) 100%);
}

@media screen and (min-width: 480px) {
  .gradientRow {
    display: block;
    margin-top: 10px;
  }
}
