.container {
  display: inline-block;
  position: relative;
  min-width: 180px;
  margin-left: 20px;
}

.innerWrapper {
  border: 1px solid #757575;
  min-height: 40px;
  display: flex;
  align-items: center;
}
.mobileWrapper {
  display: flex;
  align-items: center;
  width: 100%;

  justify-content: center;
}

.list:hover,
.selector:focus ~ .list,
.mobileSelector:focus ~ .list {
  display: initial;
  max-height: 165px;
  top: 42px;
}

.selector {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  color: var(--darkest-grey);
  outline: none;
  border: none;
  background-color: transparent;
}
.mobileSelector {
  width: 100%;
  display: flex;
  column-gap: 12px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  color: var(--darkest-grey);
  outline: none;
  border: none;
  background-color: transparent;
}

.list {
  scroll-behavior: smooth;
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 41px;
  left: 0;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 0px;
  width: 99%;
  background-color: var(--white);
  text-align: left;
  border-top: 0px;
}

.countryRegion {
  border: 2px solid var(--light-grey);
  display: flex;
  flex-direction: column;
}

.region {
  display: block;
  padding: 11px 12px;

  font-weight: bold;
  font-size: 13px;
}

.country {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: bold;
}
.country span {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: 400;
}
.countryName {
  color: var(--dark-blue);
  font-weight: 400;
  font-size: 12px;
}

.countryInList:hover {
  background-color: #dedede;
  text-decoration: underline;
  text-decoration-color: var(--dark-blue);
}

.countryInList {
  justify-content: flex-start;
  cursor: pointer;
  padding: 6px 12px;
}

.countryFlag {
  width: 24px;
  max-width: 100%;
  height: 24px;
  margin-right: 8px;
  background-image: url("../../assets/images/all-flags.png");
}

.caretIcon img {
  top: 4px;
  position: relative;
  height: 27px;
  width: 100%;
  font-size: 12px;
  filter: invert(1);
}
.caretIcon {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.country-flag-Argentina {
  background-position: -240px 0;
}

.country-flag-Brazil {
  background-position: -336px -24px;
}

.country-flag-Chile {
  background-position: -264px -48px;
}

.country-flag-Chile-ProVida {
  background-position: -264px -48px;
}

.country-flag-Colombia {
  background-position: -336px -48px;
}

.country-flag-Ecuador {
  background-position: -264px -72px;
}

.country-flag-Mexico {
  background-position: -168px -216px;
}

.country-flag-Uruguay {
  background-position: -360px -312px;
}

.country-flag-USA {
  background-position: -336px -312px;
}

.country-flag-Australia {
  background-position: -312px 0;
}

.country-flag-Bangladesh {
  background-position: -72px -24px;
}

.country-flag-China {
  background-position: -312px -48px;
}

.country-flag-Hong-Kong {
  background-position: -240px -120px;
}

.country-flag-India {
  background-position: -96px -144px;
}

.country-flag-Japan {
  background-position: -288px -144px;
}

.country-flag-Korea {
  background-position: -96px -168px;
}

.country-flag-Malaysia {
  background-position: -192px -216px;
}

.country-flag-Nepal {
  background-position: -48px -240px;
}

.country-flag-Vietnam {
  background-position: -144px -336px;
}

.country-flag-Bulgaria {
  background-position: -144px -24px;
}

.country-flag-Cyprus {
  background-position: -72px -72px;
}

.country-flag-Czech-Republic {
  background-position: -96px -72px;
}

.country-flag-France {
  background-position: -192px -96px;
}

.country-flag-Greece {
  background-position: -96px -120px;
}

.country-flag-Hungary {
  background-position: -336px -120px;
}

.country-flag-Ireland {
  background-position: -24px -144px;
}

.country-flag-Italy {
  background-position: -192px -144px;
}

.country-flag-Latvia {
  background-position: -24px -192px;
}

.country-flag-Lithuania {
  background-position: -360px -168px;
}

.country-flag-Poland {
  background-position: -312px -240px;
}

.country-flag-Portugal {
  background-position: -24px -264px;
}

.country-flag-Romania {
  background-position: -120px -264px;
}

.country-flag-Russia {
  background-position: -168px -264px;
}

.country-flag-Slovakia {
  background-position: -24px -288px;
}

.country-flag-Spain {
  background-position: 0 -96px;
}

.country-flag-Turkey {
  background-position: -168px -312px;
}

.country-flag-Ukraine {
  background-position: -288px -312px;
}

.country-flag-United-Kingdom {
  background-position: -240px -96px;
}

.country-flag-Bahrain {
  background-position: -168px -24px;
}

.country-flag-Egypt {
  background-position: -312px -72px;
}

.country-flag-Jordan {
  background-position: -264px -144px;
}

.country-flag-Kuwait {
  background-position: -120px -168px;
}

.country-flag-Lebanon {
  background-position: -216px -168px;
}

.country-flag-Oman {
  background-position: -144px -240px;
}

.country-flag-Qatar {
  background-position: -96px -264px;
}

.country-flag-Saudi-Arabia {
  background-position: -216px -264px;
}

.country-flag-United-Arab-Emirates {
  background-position: -48px 0;
}

@media screen and (min-width: 576px) {
  .container {
    min-width: 250px;
  }
}
