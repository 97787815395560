.contactUsSlider__innerContainer {
  color: #666;
  padding: 30px;
  text-transform: none;
  /* overflow: visible; */
  background-color: #fff;
  font-size: 13px;
  line-height: 30px;
  min-height: 100%;
  /* padding-bottom: 100px; */
  flex-direction: row;
}
.contactUsSlider__outerContainer.show {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.3s ease-in-out;
}
.contactUsSlider__outerContainer.hide {
  opacity: 0;
  transform: translateX(500px);
  transition: all 0.3s ease-in-out;
}
.heading h3 {
  color: black;
}
.contactUsSlider__outerContainer {
  position: fixed;
  right: 0;
  max-height: 100%;
  height: 100%;
  top: 0;
  overflow-y: auto;
  background-color: black;
  width: 100%;
  min-width: 100%;
  z-index: 9999;
  box-shadow: 0 1px 4px 1px rgba(74, 74, 74, 0.49);
}
.contactClose {
  top: 10px;
  right: 30px;
  position: absolute;
  float: right !important;
  cursor: pointer;
}
.contactUsSlider_form {
  flex-direction: row;
  justify-content: center;
  margin-bottom: 10px;
  width: 100%;
}

.subHeading p {
  font-size: 16px;
  font-weight: 400;
}
.contactUsSlider_form__font {
  font-size: 100px;
}
.outlinedBorder {
  margin-bottom: 1.75em;
}

.outlinedBorder div:first-child {
  border: 2px solid var(--medium-grey);
  border-radius: 5px;
}

.outlinedBorder div input:focus ~ label {
  top: 4px !important;
}

.outlinedBorder div label {
  top: 16px;
}

.PaymentOverview__agreement {
  /* font-family: Noto Sans; */
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  margin-left: 8px;
}

.PaymentOverview__agreement button:hover {
  color: #006ca6;
  text-decoration: underline;
}
.PaymentOverview__agreement button a {
  text-decoration: none;
  color: var(--blue2);
}

.PaymentOverview__agreement button {
  font-size: 12px;
  border: none;
  padding: 0;
  margin: 0;
  outline: 0;
  background-color: transparent;
  cursor: pointer;
  color: var(--blue2);
  text-decoration: none;
  font-weight: 400;
}
.contactUsSlider_button {
  margin-top: 20px;
}
.Outer_thank_you {
  justify-content: center;
  position: absolute;
  top: 24%;
  left: 0;
  right: 0;
  width: 80%;
  padding: 30px;
  text-align: center;
  line-height: 1.4;
}
.Inner_thank_you h2 {
  text-align: center;
  font-size: 28px;
  line-height: 32px;
  font-weight: 400;
  color: #333;
}
.thanksSubText {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #666;
}
@media screen and (min-width: 480px) {
  .contactUsSlider__outerContainer {
    min-width: auto;
    width: 100%;
  }
}
@media screen and (min-width: 570px) {
  .contactUsSlider__outerContainer {
    width: 60%;
  }
}
@media screen and (min-width: 768px) {
  .contactUsSlider__outerContainer {
    width: 40%;
  }
}
@media screen and (min-width: 920px) {
  .contactUsSlider__outerContainer {
    width: 40%;
  }
}
@media screen and (min-width: 1200px) {
  .contactUsSlider__outerContainer {
    width: 30%;
  }
}

@media screen and (min-width: 1500px) {
  .contactUsSlider__outerContainer {
    width: 20%;
  }
}
